<template>
  <v-container class="text-center h-screen">
    <v-row
      class="fill-height"
      align="center"
      justify="center"
    >
      <template v-for="(item, i) in items">
        <v-col
          :key="i"
          cols="12"
        >
          <v-hover v-slot="{ hover }">
            <v-card
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
              :to="item.to"
            >
              <v-img
                :src="item.img"
                height="215px"
              >
                <v-card-title class="ma-5 text-h4 white--text">
                  <v-row
                    class="fill-height flex-column"
                    justify="space-between"
                  >
                    <p class="mt-4 subheading text-left home_title">
                      {{ item.title }}
                    </p>

                    <div>
                      <p
                        class="mt-0 text-body-4 font-weight-bold font-italic text-left home_title"
                      >
                        {{ item.text }}
                      </p>
                      <p
                        class="mt-10 text-subtitle-2 font-weight-medium font-italic text-left home_desc"
                      >
                        {{ item.subtext }}
                      </p>
                    </div>
                  </v-row>
                </v-card-title>
              </v-img>
            </v-card>
          </v-hover>
        </v-col>
      </template>
    </v-row>
  </v-container>
  <!--
  <div class="mg-top-80">
    <v-row>
      <v-btn
        color="red"
        block
        x-large
        to="/test/sample"
      >
        Sample
      </v-btn>
    </v-row>
  </div>
  <div class="mg-top-80">
    <v-row>
      <v-btn
        color="yellow"
        block
        x-large
        to="/create"
      >
        CREATE
      </v-btn>
    </v-row>
  </div> -->
  <!-- </v-row>
    </v-col>
  </v-container>
  </v-container>-->
</template>

<script>
// Utilities
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'Main',

  components: {
    // Main: () => import('@/components/Main'),
  },
  data: () => ({
    icons: ['mdi-rewind', 'mdi-play', 'mdi-fast-forward'],
    items: [
      {
        title: '랭킹',
        text: 'RANKING',
        subtext: '인기 테스트 나도 하러가기',
        to: '/ranking',
        img: 'https://cdn.vuetifyjs.com/images/parallax/material.jpg',
      },
      {
        title: '랜덤 테스트',
        text: 'RANDOM',
        subtext: 'Play the random game',
        to: '/test/random',
        img: 'https://picsum.photos/500/300?image=2',
      },
      {
        title: '제작소',
        text: 'CREATE ',
        subtext: '가이드를 따라 테스트를 제작해보세요',
        to: '/create',
        img: 'https://cdn.vuetifyjs.com/images/parallax/material2.jpg',
      },
    ],
    transparent: 'rgba(255, 255, 255, 0)',
  }),
  // data: () => ({
  //   layout: [2, 2, 1, 2, 2, 3, 3, 3, 3, 3, 3],
  //   page: 1,
  // }),

  computed: {
    ...mapState(['articles']),
    pages() {
      return Math.ceil(this.articles.length / 11)
    },
    paginatedArticles() {
      const start = (this.page - 1) * 11
      const stop = this.page * 11

      return this.articles.slice(start, stop)
    },
  },

  watch: {
    page() {
      window.scrollTo(0, 0)
    },
  },

  async mounted() {
    this.setLoading(false)
    await this.$store.dispatch('qna/fetchRanking')
  },
  methods: {
    ...mapMutations('common', ['setLoading']),
  },
}
</script>

<style scoped>
.v-card {
  transition: opacity 0.4s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.7;
}

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}

.home_title {
  font-weight: 700;
  text-shadow: 3px 3px 3px #000;
}
.home_desc {
  text-shadow: 1px 1px 1px #000;
}
</style>
